<template>
  <v-app>
    <v-navigation-drawer
      app
      permanent
    >
      <v-app-bar flat>
        <v-toolbar-title>
          VDP Exam: Admin
        </v-toolbar-title>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="doLogout"
            >
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>
      </v-app-bar>
      <router-view name="sidebar" />
    </v-navigation-drawer>

    <router-view name="page" />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  methods: {
    ...mapActions('auth', [
      'logout',
    ]),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>
