<template>
  <v-card>
    <v-card-text>
      <OpenlayersMap
        :slide-id="slide.id"
        :levels.sync="slide.tile_levels"
        :rotation.sync="rotation"
        :extent.sync="extent"
        :drawing.sync="drawing"
      />
    </v-card-text>
    <v-card-actions>
      <div>Rotation:</div>
      <v-slider
        v-model="rotation"
        min="0"
        max="359"
        step="1"
        thumb-label
      />
      <v-btn @click="resetMetadata">
        Reset
      </v-btn>
      <v-btn @click="drawing = true">
        Crop Slide
      </v-btn>
      <v-btn
        :disabled="saving"
        color="info"
        @click="saveMetadata"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import OpenlayersMap from '@/components/SlideDetail/OpenlayersMap.vue';

export default {
  name: 'SlideImageViewer',
  components: {
    OpenlayersMap,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rotation: this.slide.rotation,
      extent: this.slide.x_min ? [
        this.slide.x_min,
        this.slide.y_min,
        this.slide.x_max,
        this.slide.y_max,
      ] : null,
      drawing: false,
      saving: false,
    };
  },
  computed: {
    ...mapState('slide', [
      'cropped',
    ]),
  },
  methods: {
    ...mapActions('slide', [
      'fetchSlides',
      'saveSlide',
    ]),
    resetMetadata() {
      this.rotation = 0;
      this.extent = null;
    },
    async saveMetadata() {
      this.saving = true;

      await this.saveSlide({
        slideId: this.slide.id,
        patch: {
          rotation: this.rotation,
          x_min: this.extent?.[0] ?? null,
          y_min: this.extent?.[1] ?? null,
          x_max: this.extent?.[2] ?? null,
          y_max: this.extent?.[3] ?? null,
        },
      });

      this.saving = false;

      if (this.cropped !== null) {
        // Changing extents can change cropped filtering
        this.fetchSlides();
      }
    },
  },
};
</script>

<style scoped>
  /deep/ .v-card__text {
    padding-top: 20px !important;
  }
</style>
