<template>
  <v-combobox
    :value="initialExams"
    :items="exams"
    item-value="id"
    item-text="name"
    multiple
    menu-props="close-on-click"
    hide-details
    placeholder="<none>"
    solo
    flat
    background-color="grey lighten-3"
    @input="update"
  >
    <template #selection="{ item: exam }">
      <v-chip
        :key="exam.id"
        class="v-chip--select-multi"
        small
        text-color="primary"
        color="white"
      >
        <span class="font-weight-medium">{{ exam.name }}</span>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { mapState } from 'vuex';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

export default {
  name: 'SlideExamPicker',
  inheritAttrs: true,
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('exam', [
      'exams',
      'loading',
    ]),
    initialExams() {
      if (this.loading) {
        return [];
      }
      const examsById = keyBy(this.exams, 'id');
      return this.value.map((examId) => examsById[examId]);
    },
  },
  methods: {
    update(updatedExams) {
      const sortedExams = sortBy(updatedExams, ['name', 'id']);
      const sortedExamIds = sortedExams.map((exam) => exam.id);
      this.$emit('input', sortedExamIds);
    },
  },
};
</script>
