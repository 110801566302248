import http from '@/http';

export default {
  namespaced: true,
  state: {
    token: null,
  },
  mutations: {
    setToken(state, { token }) {
      if (token) {
        http.defaults.headers.common.Authorization = `Token ${token}`;
      } else {
        delete http.defaults.headers.common.Authorization;
      }
      state.token = token;
    },
  },
  actions: {
    async loadToken({ commit }) {
      let token = null;
      try {
        token = window.localStorage.getItem('token') || null;
      } catch (e) {
        // No localStorage
      }
      commit('setToken', { token });
    },
    async login({ commit }, { username, password }) {
      const resp = await http.request({
        url: 'auth/',
        method: 'post',
        data: {
          username,
          password,
        },
      });
      // http.request will throw if the login was unsuccessful
      const { token } = resp.data;

      try {
        window.localStorage.setItem('token', token);
      } catch (e) {
        // No localStorage
      }
      commit('setToken', { token });
    },
    async logout({ commit }) {
      try {
        window.localStorage.removeItem('token');
      } catch (e) {
        // No localStorage
      }
      commit('setToken', { token: null });
      await http.request({
        url: 'user/authentication',
        method: 'delete',
      });
    },
  },
};
