<template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <template #activator="{ on }">
      <slot :on="on" />
    </template>
    <v-card>
      <v-card-title>
        Share Slides as CSV
      </v-card-title>
      <v-card-text>
        <v-alert
          dense
          type="info"
          class="text-body-2"
        >
          Select a credential to secure the shared slides.
          <br>
          To share the slides outside of an exam, use the <strong>{{ defaultKeyId }}</strong> key.
        </v-alert>
        <v-select
          v-model="selectedAuthKey"
          :items="authKeys"
          label="Auth Key"
          solo
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="show = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="selectedAuthKey === null"
          color="primary"
          text
          @click="submit"
        >
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { saveAs } from 'file-saver';

import http from '@/http';

export default {
  name: 'ShareAllDialog',
  data() {
    return {
      show: false,
      authKeys: [],
      defaultKeyId: '',
      selectedAuthKey: null,
    };
  },
  computed: {
    ...mapState('slide', [
      'examId',
    ]),
  },
  watch: {
    show() {
      if (!this.show) {
        this.reset();
      }
    },
  },
  async mounted() {
    const getAuthKeys = await http.request({
      method: 'get',
      url: 'auth-key/',
    });

    this.defaultKeyId = getAuthKeys.data.find((key) => key.default).key_id;

    this.authKeys = getAuthKeys.data.map((value) => ({
      value: value.id,
      text: value.key_id,
    }));
  },
  methods: {
    reset() {
      this.selectedAuthKey = null;
    },
    async submit() {
      const examsFilter = this.examId === 'none' ? { exams__isnull: 'True' } : { exams: this.examId };

      const resp = await http.request({
        url: `slide/url-csv/${this.selectedAuthKey}/`,
        method: 'get',
        params: {
          ...examsFilter,
        },
        responseType: 'blob',
      });

      const urlCsvBlob = resp.data;
      const contentDisposition = resp.headers['content-disposition'];
      const urlCsvFilename = contentDisposition.match(/filename="(.*)"/)[1];

      saveAs(urlCsvBlob, urlCsvFilename);

      this.show = false;
      this.$emit('complete');
    },
  },
};
</script>
