<template>
  <span>
    <template v-if="!editing">
      {{ internalValue }}
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn
            icon
            small
            class="my-0 grey--text"
            v-on="on"
            @click.stop="edit"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>Edit title</span>
      </v-tooltip>

    </template>
    <template v-else>
      <v-text-field
        ref="input"
        v-model="internalValue"
        placeholder="Enter title here"
        solo
        flat
        autofocus
        hide-details
        append-icon="mdi-content-save"
        :rules="[notEmpty]"
        @click:append.stop="save"
      />
    </template>
  </span>
</template>

<script>
export default {
  name: 'SlideNameField',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalValue: this.value,
      editing: false,
    };
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
  },
  methods: {
    notEmpty(value) {
      return !!value || 'Required';
    },
    edit() {
      this.editing = true;
    },
    save() {
      if (this.$refs.input.validate(true)) {
        this.editing = false;
        this.$emit('input', this.internalValue);
      }
    },
  },
};
</script>
