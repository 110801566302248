import http from '@/http';

export default {
  namespaced: true,
  state: {
    exams: [],
    loading: true,
  },
  mutations: {
    setExams(state, { exams }) {
      state.exams = exams;
    },
    setLoading(state, { loading }) {
      state.loading = loading;
    },
  },
  actions: {
    async fetchExams({ commit }) {
      commit('setLoading', { loading: true });

      const resp = await http.request({
        url: 'exam/',
        method: 'get',
      });
      const exams = resp.data;

      commit('setExams', { exams });
      commit('setLoading', { loading: false });
    },
    async addExam({ commit, dispatch }, { examName }) {
      commit('setLoading', { loading: true });
      await http.request({
        url: 'exam/',
        method: 'post',
        data: {
          name: examName,
        },
      });

      await dispatch('fetchExams');
    },
  },
};
