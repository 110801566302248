<template>
  <v-card flat>
    <div class="text-caption grey--text">
      Description:
      <template v-if="!editing">
        <v-tooltip right>
          <template #activator="{ on }">
            <v-btn
              icon
              small
              class="my-0 grey--text"
              v-on="on"
              @click.stop="edit"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit description</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-btn
              icon
              small
              class="my-0 primary--text"
              v-on="on"
              @click.stop="save"
            >
              <v-icon small>
                mdi-content-save
              </v-icon>
            </v-btn>
          </template>
          <span>Save description</span>
        </v-tooltip>
      </template>
    </div>
    <v-textarea
      ref="input"
      v-model="internalValue"
      :readonly="!editing"
      :flat="!editing"
      :placeholder="editing ? 'Enter description here' : ''"
      rows="2"
      solo
      auto-grow
      hide-details
    />
  </v-card>
</template>

<script>
export default {
  name: 'SlideDescriptionCard',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalValue: this.value,
      editing: false,
    };
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
  },
  methods: {
    edit() {
      this.editing = true;
      this.$refs.input.focus();
    },
    save() {
      this.editing = false;
      this.$emit('input', this.internalValue);
    },
  },
};
</script>
