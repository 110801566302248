var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_c('SlideNameField',{attrs:{"value":_vm.slide.name},on:{"input":function($event){return _vm.saveSlideProperty('name', $event)}}}),(!_vm.slide.tiled)?_c('v-chip',{attrs:{"outlined":"","label":""}},[_vm._v(" Pending ")]):_vm._e()],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-caption"},on),[_c('i',[_vm._v("Created "+_vm._s(_vm.createdTimeRelative))])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.createdTime))])]),_c('SlideDeleteDialog',{attrs:{"slide":_vm.slide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onDelete = ref.on;
return [_c('v-menu',{attrs:{"bottom":"","left":"","nudge-bottom":"42px","close-on-click":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("More actions")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-title',[_vm._v("Duplicate slide")])],1),_c('v-list-item',_vm._g({},onDelete),[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v(" Delete slide ")])],1)],1)],1)]}}])})],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{attrs:{"disabled":!_vm.slide.tiled,"width":"75vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-card',_vm._g({attrs:{"height":"180"}},on),[_c('img',{attrs:{"src":_vm.slide.thumbnail_url,"alt":"Slide thumbnail","height":"180"}})])]}}]),model:{value:(_vm.slideImageViewer),callback:function ($$v) {_vm.slideImageViewer=$$v},expression:"slideImageViewer"}},[_c('SlideImageViewer',{attrs:{"slide":_vm.slide}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('SlideDescriptionCard',{attrs:{"value":_vm.slide.description},on:{"input":function($event){return _vm.saveSlideProperty('description', $event)}}}),_c('SlideExternalViewerButtons',{attrs:{"slide":_vm.slide}}),_c('SlideStatusSection',{attrs:{"slide":_vm.slide}})],1)],1)],1),_c('v-container',{staticClass:"py-2 grey lighten-3"},[_c('v-row',[_c('v-col',{staticClass:"shrink",attrs:{"align-self":"center"}},[_c('span',{staticClass:"text-caption grey--text text--darken-1"},[_vm._v(" Exams: ")])]),_c('v-col',[_c('SlideExamPicker',{attrs:{"value":_vm.slide.exams},on:{"input":function($event){return _vm.saveSlideProperty('exams', $event)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }