<template>
  <v-list class="pt-0">
    <v-list-item
      :to="{ name: 'slides' }"
      replace
      exact
    >
      <v-list-item-avatar>
        <v-icon>
          mdi-view-module
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          All Slides
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-list-item-action>
    </v-list-item>

    <UploadDialog
      v-slot="{ on }"
      @complete="uploadComplete"
    >
      <v-list-item
        color="green"
        v-on="on"
      >
        <v-list-item-title class="text-md-center">
          Upload Slides
          <v-icon color="green">
            mdi-cloud-upload
          </v-icon>
        </v-list-item-title>
      </v-list-item>
    </UploadDialog>

    <v-list-item
      class="primary"
    >
      <v-list-item-content>
        <v-list-item-title>
          Exams
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <ExamCreateDialog v-slot="{ on }">
          <v-btn
            small
            v-on="on"
          >
            <v-icon
              small
              left
            >
              mdi-plus-circle
            </v-icon>
            New
          </v-btn>
        </ExamCreateDialog>
      </v-list-item-action>
    </v-list-item>

    <v-list-item
      v-for="exam in exams"
      :key="exam.id"
      :to="{ name: 'slides', params: { examId: exam.id.toString() } }"
      replace
      exact
    >
      <v-list-item-content>
        <v-list-item-title>{{ exam.name }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-badge>
          <template #badge>
            {{ exam.num_slides }}
          </template>
        </v-badge>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      :to="{ name: 'slides', params: { examId: 'none' } }"
      replace
      exact
    >
      <v-list-item-content>
        <v-list-item-title>&lt; <i>No exams</i> &gt;</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ExamCreateDialog from '@/components/ExamCreateDialog.vue';
import UploadDialog from '@/components/UploadDialog.vue';

export default {
  name: 'ExamList',
  components: {
    ExamCreateDialog,
    UploadDialog,
  },
  computed: {
    ...mapState('exam', [
      'exams',
    ]),
  },
  async created() {
    await this.fetchExams();
  },
  methods: {
    ...mapActions('exam', [
      'fetchExams',
    ]),
    ...mapActions('slide', [
      'fetchSlides',
    ]),
    async uploadComplete() {
      await this.fetchSlides();
    },
  },
};
</script>
