<template>
  <v-main>
    <v-app-bar
      flat
      color="white"
    >
      <v-toolbar-title>
        {{ examName || '&hellip;' }}
      </v-toolbar-title>

      <v-spacer />

      <!-- TODO: Width for https://github.com/vuetifyjs/vuetify/issues/8935 -->
      <v-toolbar-items style="width: 500px;">
        <v-pagination
          :value="pageNum"
          :length="numPages"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="updatePaginator"
        />
      </v-toolbar-items>

      <v-spacer />

      <v-toolbar-items>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-text-field
          :value="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          class="align-center pr-5"
          clearable
          single-line
          hide-details
          @input="updateSearchField"
        />
        <v-switch
          v-model="excludeCropped"
          class="align-center"
          label="Only Uncropped"
          :append-icon="excludeCropped ? 'mdi-filter' : 'mdi-filter-outline'"
          hide-details
        />
        <v-divider
          class="mx-4"
          inset
          vertical
        />
      </v-toolbar-items>

      <ShareAllDialog v-slot="{ on }">
        <v-btn
          color="primary"
          class="ml-2"
          v-on="on"
        >
          Share all ({{ loading ? '&hellip;' : slideCount }})
          <v-icon
            small
            right
          >
            mdi-share
          </v-icon>
        </v-btn>
      </ShareAllDialog>
    </v-app-bar>
    <v-progress-linear
      :indeterminate="loading"
      class="my-0"
      background-opacity="0"
    />
    <v-container>
      <v-row>
        <v-col
          v-for="slide in slides"
          :key="slide.id"
          cols="12"
          lg="6"
        >
          <SlideDetail
            :slide="slide"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions, mapState } from 'vuex';

import store from '@/store';
import ShareAllDialog from '@/components/ShareAllDialog.vue';
import SlideDetail from '@/components/SlideDetail/SlideDetail.vue';

export default {
  name: 'SlideList',
  components: {
    ShareAllDialog,
    SlideDetail,
  },
  beforeRouteEnter(to, from, next) {
    const examId = to.params.examId || null;
    store.dispatch('slide/updateExamId', { examId });
    next();
  },
  beforeRouteUpdate(to, from, next) {
    const examId = to.params.examId || null;
    this.updateExamId({ examId });
    next();
  },
  computed: {
    ...mapState('slide', [
      'slides',
      'slideCount',
      'loading',
      'examId',
      'pageNum',
      'search',
      'cropped',
      'numPages',
    ]),
    ...mapState('exam', [
      'exams',
    ]),
    examName() {
      let examName;
      if (this.examId === 'none') {
        examName = 'Slides with no exams';
      } else if (this.examId) {
        const exam = this.exams.find((anExam) => anExam.id.toString() === this.examId);
        examName = exam?.name;
      } else {
        examName = 'All Slides';
      }
      return examName;
    },
    excludeCropped: {
      get() {
        if (this.cropped === false) {
          return true;
        }
        if (this.cropped === null) {
          return false;
        }
        return undefined;
      },
      set(newValue) {
        // When enabled, set a filter to fetch only cropped==false, otherwise no filter
        const cropped = newValue ? false : null;
        this.updateCropped({ cropped });
      },
    },
  },
  methods: {
    ...mapActions('slide', [
      'updateExamId',
      'updateSearch',
      'updateCropped',
      'updatePageNum',
    ]),
    updatePaginator(pageNum) {
      this.updatePageNum({ pageNum });
    },
    updateSearchField: debounce(function updateSearchField(search) {
      this.updateSearch({ search });
    }, 300),
  },
};
</script>
