<template>
  <div>
    <div class="text-caption grey--text">
      Status:
    </div>
    <v-chip
      class="mr-2"
      :color="cropped ? 'success' : 'warning'"
      :outlined="cropped"
      small
    >
      <v-avatar left>
        <v-icon>{{ cropped ? 'mdi-check' : 'mdi-exclamation' }}</v-icon>
      </v-avatar>
      {{ cropped ? 'Cropped' : 'Not Cropped' }}
    </v-chip>
    <v-chip
      class="mr-2"
      outlined
      small
    >
      <v-avatar left>
        <v-icon>{{ rotated ? 'mdi-format-rotate-90' : 'mdi-square-outline' }}</v-icon>
      </v-avatar>
      {{ rotated ? 'Rotated' : 'Not Rotated' }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'SlideStatusSection',
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cropped() {
      return this.slide.x_min !== null;
    },
    rotated() {
      return this.slide.rotation !== 0;
    },
  },
};
</script>
