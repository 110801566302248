import Vue from 'vue';

import '@/plugins/sentry';
import '@/plugins/clipboard';
import '@/plugins/static';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
