<template>
  <!--need "auto " https://github.com/vuetifyjs/vuetify/issues/6919 -->
  <v-dialog
    v-model="show"
    width="auto "
  >
    <template #activator="{ on }">
      <slot :on="on" />
    </template>
    <v-card>
      <v-card-title
        primary-title
        class="text-h5"
      >
        Create Exam
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          @submit="submit"
        >
          <v-text-field
            ref="field"
            v-model="examName"
            :disabled="submitting"
            label="Exam Name"
            :rules="[v => !!v || 'Name is required']"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="submitting"
          text
          @click="show = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="submitting"
          color="primary"
          text
          @click="submit"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ExamCreateDialog',
  data() {
    return {
      show: false,
      submitting: false,
      examName: '',
    };
  },
  watch: {
    async show() {
      if (this.show) {
        await this.$nextTick();
        this.$refs.field.focus();
      } else {
        this.$refs.form.reset();
      }
    },
  },
  methods: {
    ...mapActions('exam', [
      'addExam',
    ]),
    async submit() {
      this.submitting = true;
      if (this.$refs.form.validate()) {
        try {
          await this.addExam({ examName: this.examName });
        } catch (e) {
          // TODO: handle errors (probably duplicate)
        }
        this.show = false;
        this.$router.push({
          name: 'slides',
        });
      }
      this.submitting = false;
    },
  },
};
</script>
