import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/auth';
import exam from '@/store/exam';
import slide from '@/store/slide';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    exam,
    slide,
  },
});

export default store;
