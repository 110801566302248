<template>
  <div>
    <div class="text-caption grey--text">
      Viewers:
    </div>
    <v-row>
      <v-col
        class="grow py-1 pr-1"
      >
        <v-btn
          :disabled="!slide.tiled"
          block
          color="primary"
          small
          @click="launchFullScreenViewer"
        >
          Full Screen
        </v-btn>
      </v-col>
      <v-col
        class="shrink py-1 pl-1"
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              :disabled="!slide.tiled"
              color="blue lighten-2"
              small
              v-on="on"
              @click="copyFullScreenViewerUrl"
            >
              <v-icon>mdi-clipboard-arrow-left-outline</v-icon>
              URL
            </v-btn>
          </template>
          Copy to clipboard
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="grow py-1 pr-1"
      >
        <v-btn
          block
          color="primary"
          small
          @click="launchExamModeViewer"
        >
          Exam Simulation
        </v-btn>
      </v-col>
      <v-col
        class="shrink py-1 pl-1"
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              :disabled="!slide.tiled"
              color="blue lighten-2"
              small
              v-on="on"
              @click="copyExamViewerUrl"
            >
              <v-icon>mdi-clipboard-arrow-left-outline</v-icon>
              URL
            </v-btn>
          </template>
          Copy to clipboard
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http, { examViewerBaseUrl, viewerBaseUrl } from '@/http';

export default {
  name: 'SlideExternalViewerButtons',
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // TODO: Async computed properties?
    async getFullScreenViewerUrl() {
      const resp = await http.request({
        url: `slide/${this.slide.id}/jwt/`,
        method: 'post',
      });

      return `${viewerBaseUrl}?slide_id=${this.slide.id}&token=${resp.data}`;
    },
    async getExamViewerUrl() {
      const resp = await http.request({
        url: `slide/${this.slide.id}/jwt/`,
        method: 'post',
      });

      return `${examViewerBaseUrl}?slide_id=${this.slide.id}&token=${resp.data}`;
    },
    async copyFullScreenViewerUrl() {
      const url = await this.getFullScreenViewerUrl();
      this.$copyText(url);
    },
    async copyExamViewerUrl() {
      const url = await this.getExamViewerUrl();
      this.$copyText(url);
    },
    async launchFullScreenViewer() {
      const url = await this.getFullScreenViewerUrl();
      window.open(url, '_blank');
    },
    async launchExamModeViewer() {
      const url = await this.getExamViewerUrl();
      window.open(url, '_blank');
    },
  },
};
</script>
