<template>
  <v-card>
    <v-toolbar
      flat
      dark
    >
      <v-toolbar-title>
        <SlideNameField
          :value="slide.name"
          @input="saveSlideProperty('name', $event)"
        />
        <v-chip
          v-if="!slide.tiled"
          outlined
          label
        >
          Pending
        </v-chip>
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div
            class="text-caption"
            v-on="on"
          >
            <i>Created {{ createdTimeRelative }}</i>
          </div>
        </template>
        <span>{{ createdTime }}</span>
      </v-tooltip>

      <SlideDeleteDialog
        v-slot="{ on: onDelete }"
        :slide="slide"
      >
        <!-- If the SlideDeleteDialog lives inside the v-menu,
          it will be closed when the menu is closed -->
        <v-menu
          bottom
          left
          nudge-bottom="42px"
          close-on-click
          close-on-content-click
        >
          <template #activator="{ on: menu }">
            <v-tooltip left>
              <template #activator="{ on: tooltip }">
                <v-btn
                  icon
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <span>More actions</span>
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item disabled>
              <v-list-item-title>Duplicate slide</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-on="onDelete"
            >
              <v-list-item-title class="red--text">
                Delete slide
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </SlideDeleteDialog>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-dialog
            v-model="slideImageViewer"
            :disabled="!slide.tiled"
            width="75vw"
          >
            <template #activator="{ on }">
              <v-card
                height="180"
                v-on="on"
              >
                <img
                  :src="slide.thumbnail_url"
                  alt="Slide thumbnail"
                  height="180"
                >
              </v-card>
            </template>
            <SlideImageViewer :slide="slide" />
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <SlideDescriptionCard
            :value="slide.description"
            @input="saveSlideProperty('description', $event)"
          />
          <SlideExternalViewerButtons
            :slide="slide"
          />
          <SlideStatusSection
            :slide="slide"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="py-2 grey lighten-3">
      <v-row>
        <v-col
          class="shrink"
          align-self="center"
        >
          <span class="text-caption grey--text text--darken-1">
            Exams:
          </span>
        </v-col>
        <v-col>
          <SlideExamPicker
            :value="slide.exams"
            @input="saveSlideProperty('exams', $event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

import SlideDeleteDialog from '@/components/SlideDetail/SlideDeleteDialog.vue';
import SlideDescriptionCard from '@/components/SlideDetail/SlideDescriptionCard.vue';
import SlideExamPicker from '@/components/SlideDetail/SlideExamPicker.vue';
import SlideExternalViewerButtons from '@/components/SlideDetail/SlideExternalViewerButtons.vue';
import SlideImageViewer from '@/components/SlideDetail/SlideImageViewer.vue';
import SlideNameField from '@/components/SlideDetail/SlideNameField.vue';
import SlideStatusSection from '@/components/SlideDetail/SlideStatusSection.vue';

export default {
  name: 'SlideDetail',
  components: {
    SlideDeleteDialog,
    SlideDescriptionCard,
    SlideExamPicker,
    SlideExternalViewerButtons,
    SlideImageViewer,
    SlideNameField,
    SlideStatusSection,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      slideImageViewer: false,
    };
  },
  computed: {
    createdTimeRelative() {
      return moment(this.slide.created).fromNow();
    },
    createdTime() {
      return moment(this.slide.created).format('dddd, MMMM Do YYYY, h:mm a');
    },
  },
  methods: {
    ...mapActions('slide', [
      'fetchSlides',
      'saveSlide',
    ]),
    async saveSlideProperty(key, value) {
      await this.saveSlide({
        slideId: this.slide.id,
        patch: { [key]: value },
      });
    },
  },
};
</script>
