<template>
  <div class="map" />
</template>

<script>
import isEqual from 'lodash/isEqual';
import Collection from 'ol/Collection';
import { OverviewMap, ZoomToExtent } from 'ol/control';
import Feature from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import Draw, { createBox } from 'ol/interaction/Draw';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';

import http from '@/http';
import createMap from '@/components/SlideDetail/createMap';

export default {
  name: 'OpenlayersMap',
  props: {
    slideId: {
      type: Number,
      required: true,
    },
    levels: {
      type: Number,
      required: true,
    },
    rotation: {
      type: Number,
      required: true,
    },
    extent: {
      // [x_min, y_min, x_max, y_max]
      validator(value) {
        return (value === null)
          || (value instanceof Array
              && value.length === 4
              && value.every((elem) => (typeof elem) === 'number')
          );
      },
      required: true,
    },
    drawing: {
      type: Boolean,
      required: true,
    },
  },
  static() {
    return {
      map: null,
      tileLayer: null,
      extentFeaturesCollection: new Collection(),
      draw: null,
    };
  },
  watch: {
    // Propagate external changes to mutable internal properties
    rotation() {
      this.setRotation(this.rotation);
    },
    extent() {
      this.setExtent(this.extent);
    },
    drawing() {
      this.setDrawing(this.drawing);
    },
  },
  async mounted() {
    const tilesUrlResp = await http.request({
      url: `slide/${this.slideId}/tiles-url/`,
      method: 'post',
    });
    const tilesUrl = tilesUrlResp.data;

    this.map = createMap(
      this.$el,
      tilesUrl,
      this.levels,
      this.rotation,
      this.extent,
    );

    // Populate extentFeaturesCollection
    // setExtent can't be called, since we don't want anything to be emitted
    if (this.extent !== null) {
      this.extentFeaturesCollection.push(
        new Feature(polygonFromExtent(this.extent)),
      );
    }

    // Create a layer to show the current extent, if set
    this.map.addLayer(new VectorLayer({
      source: new VectorSource({
        // features will auto-update as the collection changes
        features: this.extentFeaturesCollection,
        wrapX: false,
      }),
      style: new Style({
        // Unlike the default style, this has no fill
        stroke: new Stroke({
          color: '#3399CC',
          width: 1.25,
        }),
      }),
    }));

    // Create a layer to show the overall slide bounds
    this.map.addLayer(new VectorLayer({
      source: new VectorSource({
        features: [
          new Feature(polygonFromExtent(
            // This is the extent of the projection 'world', which the TileLayer
            // seems? to fill completely. We also use it as the default extent if
            // none is otherwise defined.
            this.map.getView().getProjection().getExtent(),
          )),
        ],
        wrapX: false,
      }),
      style: new Style({
        stroke: new Stroke({
          color: '#33CC66',
          width: 1.25,
        }),
      }),
    }));

    // Add extent drawing interactivity
    this.draw = new Draw({
      type: GeometryType.CIRCLE,
      geometryFunction: createBox(),
    });
    // TODO: It's currently assmed that this.drawing is false during creation
    this.draw.setActive(false);
    this.map.addInteraction(this.draw);
    this.draw.on('drawend', ({ feature }) => {
      this.setDrawing(false);
      // Normally, the Draw could set its "features" option and push this new feature directly
      // to extentFeaturesCollection; however, that would cause setExtent to abort early
      // when explicitly called (and fail to cause its other side effects), as the extent
      // within extentFeaturesCollection would already be the same.
      this.setExtent(feature.getGeometry().getExtent());
    });
  },
  methods: {
    getRotation() {
      const internalRotation = this.map.getView().getRotation();
      // radians to degrees
      return internalRotation * (180 / Math.PI);
    },
    setRotation(rotation) {
      if (rotation === this.getRotation()) {
        return;
      }

      const internalRotation = rotation * (Math.PI / 180);
      this.map.getView().setRotation(internalRotation);

      this.$emit('update:rotation', rotation);
    },

    getExtent() {
      return this.extentFeaturesCollection.item(0)?.getGeometry().getExtent() ?? null;
    },
    setExtent(extent) {
      if (isEqual(extent, this.getExtent())) {
        return;
      }

      // Setting extentFeaturesCollection will sync to extent display layer
      this.extentFeaturesCollection.clear();
      if (extent !== null) {
        this.extentFeaturesCollection.push(
          new Feature(polygonFromExtent(extent)),
        );
      }

      const tileLayer = this.map.getLayers().getArray()
        .find((layer) => layer instanceof TileLayer);
      tileLayer.setExtent(extent);

      // Getting controls directly from the map is more maintainable than holding
      // long-lived references in additional variables, since this is done infrequently
      const overviewMapControl = this.map.getControls().getArray()
        .find((control) => control instanceof OverviewMap);
      const overviewTileLayer = overviewMapControl.getOverviewMap().getLayers().item(0);
      overviewTileLayer.setExtent(extent);

      const zoomToExtentControl = this.map.getControls().getArray()
        .find((control) => control instanceof ZoomToExtent);
      // TODO: There is no formal API for mutating the control's extent, so this is fragile
      zoomToExtentControl.extent = extent;

      this.$emit('update:extent', extent);
    },

    getDrawing() {
      return this.draw.getActive();
    },
    setDrawing(drawing) {
      if (drawing === this.getDrawing()) {
        return;
      }

      if (drawing) {
        // Start drawing
        this.setExtent(null);
        this.draw.setActive(true);
      } else {
        // Stop drawing
        this.draw.setActive(false);
        // In case drawing was externally stopped before it finished, abort it
        this.draw.abortDrawing();
        // this.extentFeaturesCollection is auto-populated by this.draw
      }
      this.$emit('update:drawing', drawing);
    },
  },
};
</script>

<style scoped>
  .map {
    height: 70vh;
  }
</style>
