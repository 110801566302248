<template>
  <!--need "auto " https://github.com/vuetifyjs/vuetify/issues/6919 -->
  <v-dialog
    v-model="show"
    width="auto "
  >
    <template #activator="{ on }">
      <slot :on="on" />
    </template>
    <v-card>
      <v-card-title>
        Delete Slide
      </v-card-title>
      <v-card-text>
        Are you sure you want to delete <i>{{ slide.name }}</i>?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="show = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="red"
          @click="accept"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SlideDeleteDialog',
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    ...mapActions('slide', [
      'deleteSlide',
    ]),
    async accept() {
      await this.deleteSlide({ slideId: this.slide.id });
      this.show = false;
    },
  },
};
</script>
