<template>
  <v-main>
    <v-dialog
      max-width="600"
      persistent
      :value="!token"
    >
      <v-card>
        <v-card-title class="text-h6">
          Login Required
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-progress-linear
              v-if="loading"
              indeterminate
            />
            <v-form
              v-else
              @submit.prevent="onSubmit"
            >
              <!-- Giving these non-autogenerated IDs ensures that the ID value will not be
              reused by other elements in the app, which confuses password managers. -->
              <v-text-field
                id="username"
                v-model="username"
                label="Username or email"
                autocomplete="username"
                autofocus
              />
              <v-text-field
                id="password"
                v-model="password"
                label="Password"
                type="password"
                autocomplete="current-password"
              />
              <v-btn
                type="submit"
              >
                Login
              </v-btn>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LoginPage',
  data() {
    return {
      loading: true,

      username: null,
      password: null,
    };
  },
  computed: {
    ...mapState('auth', [
      'token',
    ]),
  },
  async created() {
    this.loading = true;
    await this.loadToken();
    if (this.token) {
      this.success();
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('auth', [
      'loadToken',
      'login',
    ]),
    async onSubmit() {
      this.loading = true;
      try {
        await this.login({
          username: this.username,
          password: this.password,
        });
      } catch (e) {
        this.loading = false;
        return;
      }
      this.success();
    },
    success() {
      const redirectLocation = this.$router.currentRoute.query.redirect || { name: 'home' };
      this.$router.replace(redirectLocation);
    },
  },
};
</script>
