import http from '@/http';

export default {
  namespaced: true,
  state: {
    slides: [],
    // Filters
    examId: null,
    search: '',
    cropped: null,
    slideCount: 0,
    pageNum: 1,
    numPages: 1,
    loading: true,
  },
  mutations: {
    setSlides(state, { slides }) {
      state.slides = slides;
    },
    patchSlide(state, { slideId, patch }) {
      const slide = state.slides.find((aSlide) => aSlide.id === slideId);
      Object.assign(slide, patch);
    },
    removeSlide(state, { slideId }) {
      const slideIndex = state.slides.findIndex((aSlide) => aSlide.id === slideId);
      if (slideIndex === -1) {
        throw new Error(`Could not find slideId ${slideId} to remove`);
      }
      state.slides.splice(slideIndex, 1);
    },
    setExamId(state, { examId }) {
      state.examId = examId;
    },
    setSearch(state, { search }) {
      state.search = search;
    },
    setCropped(state, { cropped }) {
      state.cropped = cropped;
    },
    setSlideCount(state, { slideCount }) {
      state.slideCount = slideCount;
    },
    setPageNum(state, { pageNum }) {
      state.pageNum = pageNum;
    },
    setNumPages(state, { numPages }) {
      state.numPages = numPages;
    },
    setLoading(state, { loading }) {
      state.loading = loading;
    },
  },
  actions: {
    async fetchSlides({ commit, state }) {
      commit('setLoading', { loading: true });
      commit('setSlides', { slides: [] });

      const examsFilter = state.examId === 'none' ? { exams__isnull: 'True' } : { exams: state.examId };
      const searchFilter = state.search === '' ? {} : { name__search: state.search };
      const croppedFilter = state.cropped === null ? {} : { cropped: state.cropped ? 'True' : 'False' };

      const resp = await http.request({
        url: 'slide/',
        method: 'get',
        params: {
          page: state.pageNum,
          ...examsFilter,
          ...searchFilter,
          ...croppedFilter,
        },
      });

      commit('setSlides', { slides: resp.data.results });
      commit('setSlideCount', { slideCount: resp.data.count });
      commit('setNumPages', { numPages: resp.data.total_pages });
      commit('setLoading', { loading: false });
    },
    async updatePageNum({ commit, dispatch }, { pageNum }) {
      commit('setPageNum', { pageNum });
      await dispatch('fetchSlides');
    },
    async resetPages({ commit, dispatch }) {
      commit('setPageNum', { pageNum: 1 });
      // slideCount and numPages will be re-set to correct values by fetchSlides
      commit('setSlideCount', { slideCount: 0 });
      commit('setNumPages', { numPages: 1 });
      await dispatch('fetchSlides');
    },
    async updateExamId({ commit, dispatch }, { examId }) {
      commit('setExamId', { examId });
      await dispatch('resetPages');
    },
    async updateSearch({ commit, dispatch }, { search }) {
      commit('setSearch', { search });
      await dispatch('resetPages');
    },
    async updateCropped({ commit, dispatch }, { cropped }) {
      commit('setCropped', { cropped });
      await dispatch('resetPages');
    },
    async saveSlide({ commit, dispatch }, { slideId, patch }) {
      commit('patchSlide', { slideId, patch });
      await http.request({
        url: `slide/${slideId}/`,
        method: 'patch',
        data: patch,
      });

      if (Object.prototype.hasOwnProperty.call(patch, 'exams')) {
        await dispatch('exam/fetchExams', null, { root: true });
      }
    },
    async deleteSlide({ commit, dispatch }, { slideId }) {
      commit('removeSlide', { slideId });
      await http.request({
        url: `slide/${slideId}/`,
        method: 'delete',
      });
      // Update counts
      await dispatch('exam/fetchExams', null, { root: true });
    },
  },
};
