import Vue from 'vue';
import Router from 'vue-router';

import ExamList from '@/components/ExamList.vue';
import LoginPage from '@/components/LoginPage.vue';
import SlideList from '@/components/SlideList.vue';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  base: '/admin/',

  routes: [
    {
      name: 'home',
      path: '/',
      redirect: {
        name: 'slides',
      },
    },
    {
      name: 'login',
      path: '/login',
      components: {
        page: LoginPage,
      },
    },
    {
      name: 'slides',
      path: '/slides/:examId?',
      components: {
        page: SlideList,
        sidebar: ExamList,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const toLogin = to.name === 'login';
  const loggedIn = !!store.state.auth.token;

  if (!loggedIn && !toLogin) {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  } else if (loggedIn && toLogin) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;
