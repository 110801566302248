import 'ol/ol.css';
import { defaults as defaultControls, OverviewMap, ZoomToExtent } from 'ol/control';
import { DragPan, MouseWheelZoom, PinchZoom } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';

// Keeping "createMap" separate makes it easier to sync with vdp-viewer
export default function createMap(element, tilesUrl, levels, rotation, extent) {
  const source = new XYZ({
    tileSize: [256, 256],
    url: tilesUrl,
    maxZoom: levels,
    wrapX: false,
  });

  const map = new Map({
    controls: defaultControls({
      zoom: true,
      rotate: false,
      attribution: false,
    }).extend([
      new ZoomToExtent({
        // If extent is not set, it defaults to the projection extent.
        extent,
        label: 'R',
        tipLabel: 'Reset to original view position',
      }),
      new OverviewMap({
        layers: [
          new TileLayer({
            source,
            extent,
          }),
        ],
        rotateWithView: true,
        collapsed: false,
        tipLabel: 'Toggle overview',
      }),
    ]),
    target: element,
    view: new View({
      minZoom: 0,
      // Allow zooming 1 past the natural resolution, to enhance detail
      maxZoom: levels + 1,
      // Specify initial center and zoom only so the OverviewMap will properly initialize
      // and bind to this View. The actual center and zoom will be re-set by View.fit.
      center: [0.0, 0.0],
      zoom: 0,
      rotation: rotation * (Math.PI / 180),
      multiWorld: true,
      // Don't bounce at the zoom limit
      smoothResolutionConstraint: false,
    }),
    interactions: [
      // "defaults" from "ol/interaction" contains too many undesired behaviors, so build
      // desired interactions from scratch
      new DragPan(),
      new MouseWheelZoom({
        // zoom to the mouse's location
        useAnchor: true,
      }),
      new PinchZoom(),
    ],
    layers: [
      new TileLayer({
        source,
        extent,
        preload: 1,
      }),
    ],
  });

  // The projection extent is a constant value, but the TileLayer's location is
  // probably? defined to exactly fill the projection extent, so it's a sensible
  // default. It is also the default used by the ZoomToExtent control.
  const initialViewExtent = extent ?? map.getView().getProjection().getExtent();
  map.getView().fit(initialViewExtent);

  return map;
}
