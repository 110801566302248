<template>
  <v-dialog
    v-model="show"
    :persistent="submitting"
    width="500"
  >
    <template #activator="{ on }">
      <slot :on="on" />
    </template>
    <v-card>
      <v-card-title>
        Upload Slides
      </v-card-title>
      <v-card-text>
        <v-alert
          dense
          type="info"
          class="text-body-2"
        >
          To select several files: hold down <i>Ctrl</i> when clicking files.
          <br>
          To select a range of files: click the first file, hold down <i>Shift</i>, then click
          the last file.
        </v-alert>
        <v-file-input
          v-model="files"
          multiple
          small-chips
          counter
          show-size
          prepend-icon="mdi-cloud-upload"
          :disabled="submitting"
          label="Slide files for upload"
        />

        <v-alert
          :value="submitting"
          border="left"
          colored-border
          color="success"
          elevation="1"
        >
          <div
            class="text-subtitle-1"
          >
            Uploading...
          </div>
          <div
            class="text-subtitle-2"
          >
            File: {{ currentUploadName }}
          </div>
          <v-progress-linear
            :value="totalProgress * 100"
            :buffer-value="totalBufferProgress * 100"
            stream
          />
        </v-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="submitting"
          text
          @click="show = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!filesSelected || submitting"
          color="primary"
          text
          @click="submit"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import S3 from 'aws-sdk/clients/s3';

import http from '@/http';

export default {
  name: 'UploadDialog',
  data() {
    return {
      show: false,
      files: [],
      submitting: false,
      currentUploadNum: 0,
      fileProgress: 0,
    };
  },
  computed: {
    filesSelected() {
      return this.files.length > 0;
    },
    totalProgress() {
      // fileProgress is an additional fractional amount in [0, 1]
      return (this.currentUploadNum + this.fileProgress) / this.files.length;
    },
    totalBufferProgress() {
      return (this.currentUploadNum + 1) / this.files.length;
    },
    currentUploadName() {
      const currentFile = this.files[this.currentUploadNum];
      return currentFile ? currentFile.name : '';
    },
  },
  watch: {
    show() {
      if (!this.show) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      this.files = [];
      this.submitting = false;
      this.currentUploadNum = 0;
      this.fileProgress = 0;
    },
    async submit() {
      this.submitting = true;

      for (let fileNum = 0; fileNum < this.files.length; fileNum += 1) {
        const file = this.files[fileNum];
        this.currentUploadNum = fileNum;
        await this.uploadFile(file); // eslint-disable-line no-await-in-loop
      }

      this.show = false;
      this.$emit('complete');
    },
    async uploadFile(file) {
      // the percent reserved for upload initiate and finalize operations
      const OVERHEAD_PERCENT = 0.05;

      this.fileProgress = 0;
      const initUpload = await http.request({
        method: 'get',
        url: `file-upload-url/${file.name}`, // TODO url encode name?
      });

      this.fileProgress = OVERHEAD_PERCENT / 2;

      const s3 = new S3({
        apiVersion: '2006-03-01',
        accessKeyId: initUpload.data.accessKeyId,
        secretAccessKey: initUpload.data.secretAccessKey,
        sessionToken: initUpload.data.sessionToken,
      });

      await s3.upload({
        Bucket: initUpload.data.bucketName,
        Key: initUpload.data.objectKey,
        Body: file,
      })
        .on('httpUploadProgress', (evt) => {
          const s3Progress = evt.loaded / evt.total;
          // s3Progress only spans the total fileProgress range [0.1, 0.9)
          this.fileProgress = (OVERHEAD_PERCENT / 2) + (s3Progress * (1 - OVERHEAD_PERCENT));
        })
        .promise();

      await http.request({
        method: 'post',
        url: 'slide/',
        data: {
          name: file.name,
          file: initUpload.data.objectKey,
        },
      });
      this.fileProgress = 1;
    },
  },
};
</script>
